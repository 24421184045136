import React from "react";
import { string, arrayOf, shape, bool } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../Base/HeadingBuilder";
import useStartMotion from "../../../hooks/use-start-motion";
import ProductCardPlainBackground from "../../Base/ProductCardPlainBackground";

const variants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const ProductsWithLinksPlainBackground = ({
  className,
  title,
  subtext,
  products,
  animations,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      data-cy="products-with-links-plain-background"
      className={`
        max-w-1440 w-full px-4 lg:px-40
        ${className}
    `}
      ref={ref}
    >
      <motion.div
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.1, duration: 0.8 }}
      >
        <Heading
          level={2}
          injectionType={2}
          withBlueSymbol
          className="xl:max-w-55-percent"
        >
          {title}
        </Heading>
      </motion.div>

      <motion.p
        className={`
        text-p text-black-300
        mt-6 lg:mt-8
        mb-10 lg:mb-20
        lg:max-w-70-percent
      `}
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.7, duration: 0.8 }}
      >
        {subtext}
      </motion.p>

      <div
        className={` 
          grid place-items-center 
          grid-cols-1 md:grid-cols-2 xl:grid-cols-3 
          gap-7
        `}
      >
        <ProductCardPlainBackground {...products[0]} />
        <ProductCardPlainBackground {...products[1]} />
        <ProductCardPlainBackground {...products[2]} />
      </div>
    </section>
  );
};

ProductsWithLinksPlainBackground.propTypes = {
  className: string,
  title: string.isRequired,
  subtext: string,
  products: arrayOf(
    shape({
      title: string,
      description: string,
      linkTo: string,
      imageName: string,
    })
  ),
  animations: bool,
};

ProductsWithLinksPlainBackground.defaultProps = {
  className: "",
  subtext: "",
  products: [],
  animations: true,
};

export default ProductsWithLinksPlainBackground;
