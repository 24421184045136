import React from "react";
import { string } from "prop-types";
import { navigate } from "gatsby";
import AppetizeImage from "../../../components/ImageQuerys/AppetizeImages";
import Title from "../Title";

const ProductCardPlainBackground = ({
  className,
  title,
  description,
  linkTo,
  imageName,
}) => {
  return (
    <div
      className={`
        product-card bg-black-800
        relative cursor-pointer
        w-full h-auto overflow-hidden
        rounded-lg z-1 group
        ${className}
    `}
      onClick={() => navigate(linkTo)}
      data-href={linkTo}
    >
      <div className="relative text-black text-h4 font-bold px-8 pt-8 z-1 group-hover:text-white delay-200">
        {title}
      </div>

      <div
        className={`
        absolute z-1 text-black-700 text-p
        left-8 top-24 right-8 
        transition delay-200
        opacity-0 group-hover:opacity-100
        `}
      >
        {description}
      </div>

      <div className="mx-8 mb-8 flex justify-between items-end">
        <div className="flex flex-col start z-1 self-end">
          <div
            className={`
            w-16 rounded-full flex justify-center
            bg-black-700 z-1
            border-solid border border-black-700 hover:border-black-500
            group-hover:bg-black
            transition-all
            py-2.5 px-6
         `}
          >
            <svg
              width="17"
              height="13"
              viewBox="0 0 17 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.6667 6.33331H1"
                stroke="#090D12"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.332 1L15.6654 6.33333L10.332 11.6667"
                stroke="#090D12"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="image flex-60">
          <AppetizeImage imageName={imageName} className=" w-full h-auto" />
        </div>
      </div>

      <div
        className={`
        product-card--hover-circle
        absolute rounded-full bg-black z-0
      `}
      />

      <style jsx>{`
        .product-card {
          max-width: 350px;
          min-height: 328px;
        }

        .product-card h3 {
          margin-bottom: 12px;
        }

        .image {
          max-width: 170px;
          max-height: 223px;
        }

        .product-card--hover-circle {
          width: 800px;
          height: 800px;
          bottom: 0;
          left: 0;
          transition: 0.5s ease-out;
          transform: translate(-100%, 100%);
        }

        .product-card:hover .product-card--hover-circle {
          transition: 0.5s ease-out;
          transform: translate(-25%, 40%);
        }

        .product-card:hover svg path {
          stroke: white;
        }
      `}</style>
    </div>
  );
};

ProductCardPlainBackground.propTypes = {
  className: string,
  title: string,
  description: string,
  linkTo: string,
  imageName: string,
};

ProductCardPlainBackground.defaultProps = {
  className: "",
  title: "",
  description: "",
  linkTo: "",
  imageName: "",
};

export default ProductCardPlainBackground;
